
import { Routes,Route } from "react-router-dom";
import './App.css';
import Home from "./pages/Home";
import Nav from "./global/Nav";
import StarryBackground from "./global/Starrybackground";

function App() {
  return (
    <div className="">
       <StarryBackground/>
    <Nav/>
     <Routes>
      <Route path="/" element={<Home/>}/>
      
     </Routes>
      
    </div>
  );
}

export default App;





