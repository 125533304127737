import React from 'react'
import hoddie1 from "../assets/articlesdemo/hoodie1-removebg-preview.png"
import hoddie2 from "../assets/articlesdemo/hoodie3-removebg-preview.png"
import shirt from "../assets/articlesdemo/shirt3-removebg-preview.png"
import shirt2 from "../assets/articlesdemo/shirtpink.png"
const Home = () => {
    return (
        <div className=' font-afacadFlux   '>
            <div className=' container mx-auto'>
            <div className=' pt-10  '>
                <h1 className='text-6xl gradient-text text-center font-medium'>Hoodies</h1>
                <div className=' flex flex-col md:flex-row  items-center justify-around mt-24'>
                {/* <span className='text-4xl text-green-500 text-center'> This is koucher</span> */}
                <div className='  backdrop-blur-sm relative border-2 top-0 h-[500px] cursor-pointer shadow-lg shadow-[#324A4C]/20 group-hover:scale-95  bg-gradient-to-b from-[#737581]/15 to-transparent flex flex-col items-center justify-center group  transition-all ease-in-out duration-500 border-[#737581]/40 rounded-2xl  w-fit'>
                    <div className='absolute w-[250px] h-[250px] rounded-full bg-red-600/80 blur-md z-0'></div>
                    <img src={hoddie1} alt='hoodie1' className=' z-10 group-hover:scale-105 hover:-mt-60 transition-all ease-in-out duration-500 group-hover:bg-blend-screen' />
                    <p className='z-10 font-medium text-xl leading-4 tracking-wide opacity-0 group-hover:opacity-100 text-white transition-opacity duration-500 delay-300'> This is an sample hoodie</p>
                    <p className='font-bold text-xl text-white group-hover:scale-110 group-hover:text-red-600/80'>400$</p>
                </div>
                <div className=' mt-20 md:mt-0 backdrop-blur-md relative border-2 top-0 h-[500px] cursor-pointer shadow-lg shadow-[#324A4C]/20  group-hover:scale-95  bg-gradient-to-b from-[#737581]/15 to-transparent flex flex-col items-center justify-center group  transition-all ease-in-out duration-500 border-[#737581]/40 rounded-2xl  w-fit'>
                    <div className='absolute w-[250px] h-[250px] rounded-full bg-[#324A4C]/80 blur-md z-0'></div>
                    <img src={hoddie2} alt='hoodie1' className=' z-10 group-hover:scale-105 hover:-mt-60 transition-all ease-in-out duration-500 group-hover:bg-blend-screen' />
                    <p className='z-10 font-medium text-xl leading-4 tracking-wide opacity-0 group-hover:opacity-100 transition-opacity duration-500 delay-300 text-white'> This is an sample hoodie</p>
                    <p className='font-bold text-2xl  group-hover:scale-110 text-white group-hover:text-[#324A4C]/80'>400$</p>
                </div>
                </div>
            </div>
            <div className=' pt-20'>
                <h1 className='text-6xl gradient-text text-center font-medium'>Shirts</h1>
                <div className=' flex flex-col md:flex-row items-center justify-around mt-24'>
                {/* <span className='text-4xl text-green-500 text-center'> This is koucher</span> */}
                <div className=' backdrop-blur-md relative border-2 top-0 h-[500px] cursor-pointer shadow-lg shadow-[#324A4C]/20 group-hover:scale-95  bg-gradient-to-b from-[#737581]/5 to-transparent flex flex-col items-center justify-center group  transition-all ease-in-out duration-500 rounded-2xl border-[#737581]/40 w-fit'>
                    <div className='absolute w-[250px] h-[250px] rounded-full bg-[#F890A9]/80 blur-md z-0'></div>
                    <img src={shirt2} alt='hoodie1' className=' z-10 group-hover:scale-105 hover:-mt-60 transition-all ease-in-out duration-500 group-hover:bg-blend-screen' />
                    <p className='z-10 font-medium text-xl leading-4 text-white tracking-wide opacity-0 group-hover:opacity-100 transition-opacity duration-500 delay-300'> This is an sample hoodie</p>
                    <p className='font-bold text-xl group-hover:scale-110 text-white group-hover:text-[#F890A9]/80'>400$</p>
                </div>
                <div className=' mt-20 md:mt-0 backdrop-blur-md relative border-2 top-0 h-[500px] cursor-pointer shadow-lg shadow-[#324A4C]/20 group-hover:scale-95  bg-gradient-to-b from-[#737581]/15 to-transparent flex flex-col items-center justify-center group  transition-all ease-in-out duration-500 rounded-2xl border-[#737581]/40 w-fit'>
                    <div className='absolute w-[250px] h-[250px] rounded-full bg-[#F890A9]/80 blur-md z-0'></div>
                    <img src={shirt2} alt='hoodie1' className=' z-10 group-hover:scale-105 hover:-mt-60 transition-all ease-in-out duration-500 group-hover:bg-blend-screen' />
                    <p className='z-10 font-medium text-xl leading-4 text-white tracking-wide opacity-0 group-hover:opacity-100 transition-opacity duration-500 delay-300'> This is an sample hoodie</p>
                    <p className='font-bold text-xl group-hover:scale-110 text-white     group-hover:text-[#F890A9]/80'>400$</p>
                </div>
                <div className=' mt-20 md:mt-0 backdrop-blur-md relative border-2 top-0 h-[500px] cursor-pointer shadow-lg group-hover:scale-95  bg-gradient-to-b from-[#737581]/15 to-transparent flex flex-col items-center justify-center group  transition-all ease-in-out duration-500 rounded-2xl border-[#737581]/40 w-fit'>
                    <div className='absolute w-[250px] h-[250px] rounded-full bg-[#E65399]/80 blur-md z-0'></div>
                    <img src={shirt} alt='hoodie1' className=' z-10 group-hover:scale-105 hover:-mt-60 transition-all ease-in-out duration-500 group-hover:bg-blend-screen' />
                    <p className='z-10 font-medium text-xl leading-4 text-white tracking-wide opacity-0 group-hover:opacity-100 transition-opacity duration-500 delay-300'> This is an sample hoodie</p>
                    <p className='font-bold text-xl group-hover:scale-110 text-white group-hover:text-[#E65399]/80'>400$</p>
                </div>
                </div>
            </div>
            </div>

        </div>
    )
}

export default Home
