import React, { useState } from 'react'
import ShoppingCart from './ShoppingCart'

const Nav = () => {
    const [openCart, setOpenCart] = useState(false)
    return (
        
        <div className=' ' >
            <div className='flex  items-center  font-afacadFlux py-2 justify-between container mx-auto bg-transparent  border-gray-400 mt-2'>
                <div>
                    <h1 className='text-5xl border-b-2 pb-2 font-semibold text-white uppercase'>

                    Koshur
                    </h1>
                </div>
                <div className='text-xl '>
                   
                    <button className='text-white' onClick={(()=>{setOpenCart(true)})}>Cart</button>
                </div>
            </div>
           { openCart && <ShoppingCart setOpenCart={setOpenCart}/>}
        </div>
    )
}

export default Nav
